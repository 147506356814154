import React from "react";
import Hero from "./Hero";
import Services from "./Services";
import Features from "./Features";
import Whyus from "./Whyus";

function Main() {
  return (
    <div>
      <Hero />
      <Whyus />
      <Services />
      <Features />
    </div>
  );
}

export default Main;
